import React, { Component } from "react";
import Modal from "./Modal";
import logo from "../Assets/Icons/map-marker.png";
import logo_black from "../Assets/Icons/map-marker-black.png";
import Geocode from "react-geocode";
import axios from "axios";
const publicIp = require('public-ip');

var region = null;
var location = null;
var city = "Select City";
var province = "";
var country = "Canada";
var postal = null;
var countryCode = null;

if (sessionStorage.getItem("location")) {
  location = sessionStorage.getItem("location");
} else {
  location = "Toronto";
}
if (sessionStorage.getItem("region")) {
  region = sessionStorage.getItem("region");
} else {
  //region = "gta";
}
if (sessionStorage.getItem("countryCode")) {
  countryCode = sessionStorage.countryCode;
}
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0");
Geocode.setLanguage("en");
Geocode.setRegion("CA");

const ottawa = {
  latitude: 45.3342894,
  longitude: -75.7164072,
};
const toronto = {
  latitude: 43.8314437,
  longitude: -79.5223975,
};
const vancouver = {
  latitude: 49.26388,
  longitude: -123.11792,
};
const calgary = {
  latitude: 51.01060,
  longitude: -114.12609,
};
const chicago = {
  latitude: 41.8781,
  longitude: -87.6298,
}

const boston = {
  latitude: 42.3601,
  longitude: -71.0589,
}

const denver = {
  latitude: 39.7392,
  longitude: -104.9903,
}

const newyork = {
  latitude: 40.7128,
  longitude: -74.0060,
}

const philadelphia = {
  latitude: 39.9526,
  longitude: -75.1652,
}

const sandiego = {
  latitude: 32.7157,
  longitude: -117.1611,
}

const sanfran = {
  latitude: 37.7749,
  longitude: -122.4194,
}

const seattle = {
  latitude: 47.6062,
  longitude: -122.3321,
}

const washingtondc = {
  latitude: 38.9072,
  longitude: -77.0369,
}

function haversine_distance(pos_lat, pos_long, lat, long) {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = pos_lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (long - pos_long) * (Math.PI / 180); // Radian difference (longitudes)
  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
        Math.cos(rlat1) *
        Math.cos(rlat2) *
        Math.sin(difflon / 2) *
        Math.sin(difflon / 2)
      )
    );
  return d;
}

Number.prototype.toRad = function () {
  return (this * Math.PI) / 180;
};

function haversine_distance_new(pos_lat, pos_long, lat, long) {
  var lat1 = pos_lat;
  var lon1 = pos_long;
  var lat2 = lat;
  var lon2 = long;

  var R = 6371; // km
  //has a problem with the .toRad() method below.
  var x1 = lat2 - lat1;
  var dLat = x1.toRad();
  var x2 = lon2 - lon1;
  var dLon = x2.toRad();
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1.toRad()) *
    Math.cos(lat2.toRad()) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  return d;
}

function hav_distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

function calculateRegion(lat, lng) {
  console.log("Latitude is :", lat);
  console.log("Longitude is :", lng);

  let distance = [];

  distance["ottawa"] = hav_distance(
    ottawa.latitude,
    ottawa.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from ottawa is " + distance["ottawa"]);

  distance["gta"] = hav_distance(
    toronto.latitude,
    toronto.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from Toronto is " + distance["gta"]);

  distance["vancouver"] = hav_distance(
    vancouver.latitude,
    vancouver.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from vancouver is " + distance["vancouver"]);

  distance["calgary"] = hav_distance(
    calgary.latitude,
    calgary.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from calgary is " + distance["calgary"]);

  distance["chicago"] = hav_distance(
    chicago.latitude,
    chicago.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from chicago is " + distance["chicago"]);

  
  distance["boston"] = hav_distance(
    boston.latitude,
    boston.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from boston is " + distance["boston"]);
  
  distance["denver"] = hav_distance(
    denver.latitude,
    denver.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from denver is " + distance["denver"]);
  
  distance["newyork"] = hav_distance(
    newyork.latitude,
    newyork.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from newyork is " + distance["newyork"]);
  
  distance["philadelphia"] = hav_distance(
    philadelphia.latitude,
    philadelphia.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from philadelphia is " + distance["philadelphia"]);
  
  distance["sandiego"] = hav_distance(
    sandiego.latitude,
    sandiego.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from sandiego is " + distance["sandiego"]);
  
  distance["sanfran"] = hav_distance(
    sanfran.latitude,
    sanfran.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from sanfran is " + distance["sanfran"]);
  
  distance["seattle"] = hav_distance(
    seattle.latitude,
    seattle.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from seattle is " + distance["seattle"]);
  
  distance["washingtondc"] = hav_distance(
    washingtondc.latitude,
    washingtondc.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from washingtondc is " + distance["washingtondc"]);
  
  

  let distancesValues = [distance["ottawa"],distance["gta"],distance["vancouver"],distance["calgary"],distance["chicago"],distance["boston"],distance["denver"],distance["newyork"],distance["philadelphia"],distance["sandiego"],distance["sanfran"],distance["seattle"],distance["washingtondc"]];
  let distances = ['ottawa','gta','vancouver','calgary', 'chicago', 'boston','denver','newyork','philadelphia','sandiego','sanfran','seattle','washingtondc'];
  console.log("distances",distances);

  let value = Array.min(distancesValues);
  let nearest = getIndexLowest(distancesValues);

  console.log("value distance is " + value);
  console.log("nearest distance is " + nearest);

  return distances[nearest]

  // if (distance["ottawa"] < distance["toronto"]) {
  //   return "ottawa";
  // } else {
  //   return "gta";
  // }
}
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
function getNearest(array) {
  let value, value2 = 0;
  let selKey;
  // for(var key in array){
  //   for(var key2 in array[key]){
  //     console.log("Key: " + key2)      
  //     value2 = array[key][key2];
  //     console.log("Value2: " + value2);
  //     if(parseFloat(value2) < parseFloat(value)){ //if this value is less then previous
  //       console.log("Value: " + value);
  //       selKey = key2;
  //       continue;
  //     }
  //     value = value2;
  //   }
  // }
  // return selKey;
  array.forEach(element => {
    //value = element.value;
    console.log(element);
  });
}

function getIndexLowest(temp) {
  var index = 0;
  var value = temp[0];
  for (var i = 0; i < temp.length; i++) {
    if (temp[i] < value) {
      value = temp[i];
      index = i;
    }
  }
  return index;
}

Array.min = function (array) {
  return Math.min.apply(Math, array);
};

function checkForArea(rad, fromPosition, toPosition) {
  let distance = 100;
  //let distance = locationA.distanceTo(locationB);
  if (distance / 1000 <= rad) return true;
  else return false;
}

class GeoLocation extends Component {
  constructor(props) {
    super(props);
    this.openLocateModal = this.openLocateModal.bind(this);
    this.changeRegionData = this.changeRegionData.bind(this);
    this.state = {
      data: this.props.data,
      isModalOpen: false,
      location: location,
      region: null,
      country: sessionStorage.getItem("country"),
      activeClasses: [false, false],
      city: city,
      province: province,
      postal: postal,
    };
  }

  openLocateModal() {
    this.toggleModal();
  }

  async checkIPGeolocate() {
    console.log('IP Address Fallback')

    //fallback to IP address API locate
    publicIp.v4().then(response => {

      console.log(response);

      axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=beaca91296ad435bb9b5a231fe1234f4&ip=" + response)
        .then(async data => {
          console.log('Axios get return https://api.ipgeolocation.io/ipgeo?apiKey=beaca91296ad435bb9b5a231fe1234f4&ip=' + response)
          console.log(data)

          let latitude = data.data.latitude;
          let longitude = data.data.longitude;
         
          let country = this.state.country;

          if (data?.data?.country_name) {
            country = data?.data?.country_name;
            sessionStorage.setItem("country", country);
          }

          region = await calculateRegion(data.data.latitude, data.data.longitude);
          this.updateCity(data.data.latitude, data.data.longitude);

          console.log('geolocated region is ' + region);
          this.changeRegionData(region);

          this.setState({
            data: window.$json,
            lat: latitude,
            lng: longitude,
            country
          }, () => console.log(latitude, longitude)
          );
        });

    })
  }

  async changeRegionData(region) {

    let json = null;
    let country = this.state.country;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.reg) region = params.reg;

    await import("../Assets/Data/" + region + ".json").then(function (
      response
    ) {
      window.$json = response;
      json = response;
      countryCode = response.countryCode;

      if (response && response?.location[0] && response.location[0].country) {
        sessionStorage.setItem("country", response.location[0].country );
        country = response.location[0].country;
      }

      if (countryCode === "US") {
        country = "United States";
      } else {
        country = "Canada";
      }


      console.log("changeRegionData called for " + region);
      console.log(response);
      switch (region) {
        case "ottawa":
          location = "Ottawa";
          city = "Ottawa";
          province = "Ontario";
          break;
        case "gta":
          location = "Greater Toronto Area";
          city = "Greater Toronto Area";
          province = province || "Ontario";
        break;
        case "calgary":
          location = "Calgary";
          city = "Calgary";
          province = province || "Alberta";
        break;
        case "chigago":
          location = "chigago";
          city = "chigago";
          province = "chigago";
        break;
        case "vancouver":
          location = "Vancouver";
          city = "Vancouver";
          province = province || "British Columbia";
        break;
        case "boston":
          location = "boston";
          city = "boston";
          province = ""
        case "denver":
          location = "denver";
          city = "denver";
          province = ""
        case "newyork":
          location = "newyork";
          city = "newyork";
          province = ""
        case "philadelphia":
          location = "philadelphia";
          city = "philadelphia";
          province = ""
        case "sandiego":
          location = "sandiego";
          city = "sandiego";
          province = ""
        case "sanfran":
          location = "sanfran";
          city = "sanfran";
          province = ""
        case "seattle":
          location = "seattle";
          city = "seattle";
          province = ""
        case "washingtondc":
          location = "washingtondc";
          city = "washingtondc";
          province = ""
        
      }

      if (params && params.loc) {
        var loc = params.loc.split('_');
        city = loc[0];
        province = loc[1];

        removeParam('loc');
        removeParam('reg');
        removeParam('postal');
      }

    });
    console.log("Finished Json Import");
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("region", region);
    sessionStorage.setItem("countryCode", countryCode);

    this.props.updateFunc(window.$json);
    this.setState({ city, province, country });
    // this.setState({ state: this.state });

    function removeParam(paramName) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(paramName);
        if (window.history.replaceState) {
            let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
            let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname +  searchString + window.location.hash;
            window.history.replaceState(null, '', newUrl);
        }
    }

  }

  handleRegionClick = (value) => {
    console.log(value);
    this.changeRegionData(value);
  };

  toggleModal = () => {
    this.setState({
      header: "Change Your City",
      isModalOpen: !this.state.isModalOpen,
    });
  };

  onUpdate = async (e) => {
    let value = document.querySelector('[name="postal_code"]').value;
    this.runGeocode(value);
    this.toggleModal();
  };

  runGeocode = async (value) => {
    if (value.length > 0) {
      sessionStorage.setItem("postal", value);
      this.setState({ postal: value });
    }

    let lat,
      lng = null;
    await Geocode.fromAddress(value).then(
      async (response) => {
        lng = response.results[0].geometry.location.lng;
        lat = response.results[0].geometry.location.lat;
        console.log(lat, lng);
        this.setState({
          lat: lat,
          lng: lng,
        }, () => {
          // Once we have the lat and lng, we can pass it to the Google API to get the country
          axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.lat},${this.state.lng}&key=AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0`).then((res) => {
            let countryCode = '';

            res.data.results.map(i => {
              if (i.types.includes('country')) {
                countryCode = i.address_components[0].short_name;
              }
            });

            sessionStorage.setItem('countryCode', countryCode);
          });
        });
      },
      (error) => {
        console.error(error);
      }
    );

    region = await calculateRegion(lat, lng);
    console.log("geolocated region is " + region);
    this.changeRegionData(region);
    this.updateCity(lat, lng);
  };

  clickToggle = (index) => {
    const newClasses = [false, false];
    newClasses[index] = true;
    this.setState({
      activeClasses: newClasses,
    });
  };

  render() {
    const activeClasses = this.state.activeClasses.slice();

    return (
      <>
        <div
          onClick={this.openLocateModal}
          className="message geolocation-tab"
        >
          <img src={logo} />
          <span>{this.state.city + " " + this.state.province}</span>
        </div>

        <Modal
          show={this.state.isModalOpen}
          header={this.state.header}
          image={logo_black}
          onClose={this.toggleModal}
          onSubmit={this.onUpdate}
        >
          <div style={{ position: 'relative' }}  className="d-flex postal_input">
            {/* <label style={{ marginRight: '10px' }}>Enter Postal Code</label> */}
            <input
              style={{ minHeight: '34px', marginLeft: '0' }}
              type="text"
              name="postal_code"
              maxLength="7"
              minLength="6"
              pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
              className="postal_input"
              // placeholder="Postal Code"
              placeholder={ this.state.country === "United States" ? "Enter ZIP Code" : "Enter Postal Code" }
            />
            <a className="blue-btn" 
              style={{
                right: '-45px',
                position: 'absolute',
                background: '#fff',
                padding: '4px 8px',
                border: '1px solid #d2d2d2',
                top: '-100px',
                borderRadius: '50%',
              }} 
              onClick={this.toggleModal}>
              &#10006;
            </a>
          </div>

          {/* <div className="flexBox">
            <ul className="regionList">
              <li
                className={this.state.activeClasses[0] ? "active" : null}
                onClick={(event) => {
                  this.handleRegionClick("ottawa");
                  this.clickToggle(0);
                }}
                data-region="ottawa"
              >
                Ottawa
              </li>
              <li
                className={this.state.activeClasses[1] ? "active" : null}
                onClick={(event) => {
                  this.handleRegionClick("gta");
                  this.clickToggle(1);
                }}
                data-region="gta"
              >
                Greater Toronto
              </li>
            </ul>
          </div> */}
        </Modal>
      </>
    );
  }
  setStateData(json) {
    this.setState({ data: json });
    console.log(json);
  }
  updateCity = (lat, long) => {
    console.log("updating city function");
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0].formatted_address;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                this.setState({ city: city });
                break;
              case "administrative_area_level_1":
                province = response.results[0].address_components[i].long_name;
                this.setState({ province: province });
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        console.log(city, province, country);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  componentDidMount() {

    if (!window.URLSearchParams)
    (function (w) {

        w.URLSearchParams = w.URLSearchParams || function (searchString) {
            var self = this;
            self.searchString = searchString;
            self.get = function (name) {
                var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
                if (results == null) {
                    return null;
                }
                else {
                    return decodeURI(results[1]) || 0;
                }
            };
        }

    })(window)

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.postal && params.postal !== 'null') {
      sessionStorage.setItem("postal", params.postal);
    }

    if (sessionStorage.getItem("postal")) {
      let value = sessionStorage.getItem("postal");
      this.runGeocode(value);
    } else if (region && window.$json !== null) {
      this.changeRegionData(region);
    } else {
      this.checkIPGeolocate();
      if ("geolocation" in navigator) {

        let success = async (position) => {
          // console.log(position);

          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;

          region = await calculateRegion(
            position.coords.latitude,
            position.coords.longitude
          );
          this.updateCity(position.coords.latitude, position.coords.longitude);

          this.changeRegionData(region);

          this.setState({
            data: window.$json,
            lat: latitude,
            lng: longitude,
          });
        };

        let error = () => {
          this.checkIPGeolocate();
        }
        navigator.geolocation.enableHighAccuracy = true;
        navigator.geolocation.getCurrentPosition(success, error);
      } else {
        console.log("Not Available");
      }
    }
  }
}

export default GeoLocation;
